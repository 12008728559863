<template>
    <el-row>
    <div class="clear"></div>
    <div class="topbj">
        <div class="ztop">
            <a href="/" class="zlogo fl"><img :src="$img(siteInfo.logo)" alt="新疆活性炭"/></a>
            <div class="zsearch">
                <form >
                    <input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search"
                           maxlength="50" />
                    <button type="button" size="small" @click="search"></button>
                </form>
                <div class="zsearch_key">
                    <a  v-for="(item, index) in hotSearchWords" :key="index"
                       @click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">{{item}}</a>

                </div>
            </div>
            <div class="ewm fr">
                <h1><img src="../../assets/image/etxt.png"></h1>
                <div class="img"><img :src="$img(siteInfo.web_qrcode)"></div>
            </div>
        </div>
        <!--End header End-->
        <div class="clear"></div>

        <!--导航-->
        <div class="nav_bg" >
            <div class="navmenu">
                <li class="navr fl">
                    <a v-for="(nav_item, nav_index) in navList"
                       :key="nav_index" @click="navUrl(nav_item.url, nav_item.is_blank)">{{ nav_item.nav_title }}</a>

                </li>
            </div>
        </div>
        <!--头部结束-->
    </div>
    <div class="clear"></div>
    </el-row>
<!-- 	<div class="header-in">
		<el-row>
			<el-col :span="6">
				<router-link to="/" class="logo-wrap">
					<img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
					<img v-else src="@/assets/images/logo.png" />
				</router-link>
			</el-col>
			<el-col :span="13">
				<div class="in-sousuo">
					<div class="sousuo-box">
						<el-dropdown @command="handleCommand" trigger="click">
							<span class="el-dropdown-link">
								{{ searchTypeText }}
								<i class="el-icon-arrow-down"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="goods">商品</el-dropdown-item>
								<el-dropdown-item command="shop">店铺</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search"
							maxlength="50" />
						<el-button type="primary" size="small" @click="search">搜索</el-button>
					</div>
					<div class="hot-search-words" v-if="hotSearchWords.length">
						<span style="font-weight: bold;color: #7a7160;margin-left: 10px;font-size: 12px;">热搜：</span>
						<ul>
							<li v-for="(item, index) in hotSearchWords" :key="index"
								@click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })"
								style="color: #7a7160;">
								<span style="color: #7a7160;">{{ item }}</span>
								<span v-if="hotSearchWords[index+1]" style="color: #9d9d9d;margin-left: 10px;"></span>
							</li>

						</ul>
					</div>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="cart-wrap">
					<router-link class="cart" to="/cart" style="right: 153px;width: 62px; background-color: #fff; color: #000;">

						<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
								class="iconfont icongouwuche"></i></el-badge>
						<i v-else class="iconfont icongouwuche"></i>
						<span>购物车</span>
					</router-link>
					<!-- 	<router-link class="cart" to="/#" style="width: 98px;">

						<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
								class="iconfont qrcode"></i></el-badge>
						<i v-else class="iconfont erweima"></i>
						<span>手机扫码购买</span>
					</router-link>

					<div class="list">
						<template v-if="cartList.length">
							<h4>最新加入的商品</h4>
							<div class="overflow-wrap">
								<ul :class="{ overflow: cartList.length > 5 }">
									<li class="item" v-for="(item, index) in cartList" :key="index">
										<div class="img-wrap"><img :src="$img(item.sku_image, { size: 'mid' })"
												@error="imageError(index)" :alt="item.sku_name" /></div>
										<div class="goods-name">{{ item.sku_name }}</div>
										<div class="operation">
											<p>￥{{ item.discount_price }}x{{ item.num }}</p>
											<span @click="deleteCart(index)">删除</span>
										</div>
									</li>
								</ul>
							</div>
							<div class="total">
								<span>
									共
									<strong>{{ cartList.length }}</strong>
									种商品，总金额
									<strong>{{ cartTotalPrice }}</strong>
									元
								</span>

								<el-button type="primary" size="mini" @click="$router.push('/cart')">去购物车</el-button>
							</div>
						</template>
						<div class="empty" v-else>
							<i class="iconfont icongouwuche"></i>
							<span>您的购物车是空的，赶快去逛逛，挑选商品吧！</span>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="cart-wrap">
					<!-- <router-link class="cart" to="/cart" style="right: 153px;width: 62px;">

						<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
								class="iconfont icongouwuche"></i></el-badge>
						<i v-else class="iconfont icongouwuche"></i>
						<span>购物车</span>
					</router-link>
					<router-link class="cart" to="/#" style="width: 98px; background-color: #fff; color: #000;">

						<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
								class="iconfont iconshouji"></i></el-badge>
						<i v-else class="iconfont iconshouji"></i>
						<span>手机扫码购买</span>
					</router-link>

					<div class="list" >
						<template v-if="qrcode">
							<!-- <h4>打开手机扫一扫功能扫码购物！</h4>
							<div class="overflow-wrap">
								<ul>
									<li class="item">
										<div class="img-wrap"><img :src="$img(qrcode)" /></div>
										<div class="goods-name">打开手机扫一扫功能扫码购物</div>

									</li>
								</ul>
							</div>

						</template>
						<div class="empty" v-else>
							<i class="iconfont iconshouji"></i>
							<span>该站点未开启移动端访问，敬请期待！</span>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div> -->
</template>

<script>
	// import { mapGetters } from "vuex"
	import { getToken } from "@/utils/auth"
	import Config from "@/utils/config";
    // import { navList } from '@/api/website';
	import {
		mapGetters
	} from 'vuex';
	import {
		apiHotSearchWords,
		apiDefaultSearchWords
	} from '@/api/pc';
	import {
		cartList as apiCartList
	} from '@/api/goods/cart';
    import { navList } from "@/api/website"
	export default {
		props: {},
		data() {
			return {
				searchType: 'goods',
				searchTypeText: '商品',
				keyword: '',
				hotSearchWords: [],
				defaultSearchWords: '',
				cartList: [],
                navList: [],
				cartTotalPrice: 0,
				// qrcode:''
			};
		},
		components: {},
		computed: {
			
			...mapGetters(['cartCount', 'siteInfo', 'defaultGoodsImage', 'member','wapQrcode']),
			qrcode: function() {
				console.log(this.wapQrcode.path.h5.img)
			    return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
			},
		},
		created() {
			 this.$store.dispatch("site/qrCodes")
			 this.keyword = this.$route.query.keyword || '';
			if (this.$route.name == 'street') this.searchType = 'shop';
			else this.searchType = 'goods';
			this.$store.dispatch('site/siteInfo');
			this.getHotSearchWords();
			this.getDefaultSearchWords();
			this.getCartList();
            this.nav();
		},
		watch: {
			searchType() {
				this.searchTypeText = this.searchType == 'goods' ? '商品' : '店铺';
			},
			$route(curr) {
				if (this.keyword !== curr.query.keyword) {
					this.keyword = curr.query.keyword;
				}
				if (this.$route.name == 'street') this.searchType = 'shop';
				else this.searchType = 'goods';
			},
			cartCount() {
				if (this.member) this.getCartList();
			},
			member() {
				if (!this.member) {
					this.$store.commit('cart/SET_CART_COUNT', 0);
					this.cartList = [];
					this.cartTotalPrice = 0;
				}
			}
		},
		methods: {
            nav() {
                navList({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.navList = res.data.list;
                            for (let i in this.navList) {
                                this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
                            }
                            this.initNav(this.$route.path);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },
            navUrl(url, target) {
                if (!url) return;
                if (url.indexOf('http') == -1) {
                    if (target) this.$router.pushToTab({ path: url });
                    else this.$router.push({ path: url });
                } else {
                    if (target) window.open(url);
                    else window.location.href = url;
                }
            },
            initNav(path) {
                for (let i in this.navList) {
                    if (this.navList[i]['url'] == path) {
                        this.navSelect = path;
                        continue;
                    }
                }
            },
			handleCommand(command) {
				this.searchType = command;
			},
			search() {
				if (this.searchType == 'goods') this.$router.push({
					path: '/list',
					query: {
						keyword: this.keyword
					}
				});
				else this.$router.push({
					path: '/street',
					query: {
						keyword: this.keyword
					}
				});
			},
			getHotSearchWords() {
				apiHotSearchWords({}).then(res => {
					if (res.code == 0 && res.data.words) {
						this.hotSearchWords = res.data.words.split(',');
					}
				});
			},
			getDefaultSearchWords() {
				apiDefaultSearchWords({}).then(res => {
					if (res.code == 0 && res.data.words) {
						this.defaultSearchWords = res.data.words;
					}
				});
			},
			// 获取购物车数据
			getCartList() {
				apiCartList({})
					.then(res => {
						if (res.code >= 0 && res.data.length) {
							this.cartTotalPrice = 0
							this.cartList = res.data
							this.cartList.forEach(item => {
								this.cartTotalPrice += item.discount_price * item.num
							});
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
						}
					})
					.catch(res => {});
			},
			imageError(index) {
				this.cartList[index].sku_image = this.defaultGoodsImage;
			},
			// 删除单个购物车商品
			deleteCart(index) {
				this.$store
					.dispatch('cart/delete_cart', {
						cart_id: this.cartList[index].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.cartList.splice(index, 1);
							this.$forceUpdate();
						}
					})
					.catch(err => {});
			}
		}
	};
</script>

<style scoped lang="scss">
    @charset "utf-8";
    /* CSS Document */
    body {font-family: "宋体",Helvetica,Arial,sans-serif;font-size:12px; margin:0;padding:0;color:#333; background-color:#fff;}
    div { float:none; margin:0px auto; padding:0;}
    body>div{margin:0 auto}
    form,img,ul,ol,li,dl,dt,dd,input,p,br,button{margin: 0; padding: 0; border: 0;}
    ul,li,ol {list-style: none;}
    h1,h2,h3,h4,h5,h6 { margin:0; padding:0;}
    a { text-decoration: none; color: #333 }
    a:hover { text-decoration: none;}
    i,em { font-style:normal;}
    img { border:0;}
    .clear { clear:both; line-height:0; height:0; display:block;}
    .fr { float: right; }
    .fl { float: left; }
    a:focus { outline:none;}
    a:hover{ -webkit-transition:all 0.5s;-webkit-transiton-delay:0.6s;}
    .trans{transition: all .3s ease;-webkit-transition: all .3s ease;-moz-transition: all .3s ease;-ms-transition: all .3s ease;-o-transition: all .3s ease;-webkit-backface-visibility: hidden;}
    .trans:hover{transform:scale(1.04,1.04);-ms-transform:scale(1.04,1.04); -webkit-transform:scale(1.04,1.04);}
    /**头部样式**/

    .ztoolbar_bg { width: 100%; height:40px; line-height:40px;min-width:1190px; background:#f0f0f0;  overflow:hidden; }
    .ztoolbar{ width: 1190px; height:auto; color:#494949;font:14px/40px '宋体';}
    .ztoolbar .fl:nth-child(1) a{ color:#ff7200;}
    .ztoolbar .fl:nth-child(1) a:hover{ color:#666666}
    .ztoolbar .zright{color:#494949; font-family:"宋体"}
    .ztoolbar .zright a { display:inline-block; margin:0 5px;color:#525252;font:12px/40px '宋体';}
    .ztoolbar .zright a:hover{ color:#f98e0a;}
    .topbj{ background:#fff;}
    .ztop { height:147px;width: 1190px;}
    .ztop .zlogo {margin-top:29px; overflow:hidden;}
    /*搜索开始*/
    .zsearch{ float:left; height:85px;width:527px; margin:40px 0px 0px 90px;}
    .zsearch form input[type="text"]{ width:430px; height:44px; line-height:33px; border:2px #bd271f solid; background-color:#fff; color:#c8c8c8; text-indent:1em; outline:none; float:left; font:14px /44px '宋体'; color:#CCC;}
    .zsearch form button[type="button"]{ width:90px; height:48px; border:none; float:left;outline:none; font-size:14px;letter-spacing:4px; color:#bd271f; background:url(../../assets/image/search_btn.png) no-repeat center #bd271f;  cursor:pointer;}
    .zsearch_key { margin-top:58px; margin-right:50px; color: #b2b2b2; font-size:14px;margin-left: 20px;font-family:"微软雅黑" }
    .zsearch_key a { color: #b2b2b2; text-decoration: none; padding-right:12px; }
    //.zsearch form button[type="button"]{ width:430px; height:44px; line-height:33px; border:2px #bd271f solid; background-color:#fff; color:#c8c8c8; text-indent:1em; outline:none; float:left; font:14px /44px '宋体'; color:#CCC;}
    .zsearch_key a:nth-child(2){ color:#bd271f}
    .zsearch_key a:hover { color:#ff7e26;}

    /*二维码*/
    .ewm { width:180px; margin-top:35px; }
    .ewm h1{ float: left; margin: 22px 0 0 0; }
    .ewm .img{ width: 78px; height: 78px; float: right; border: #f3f3f3 solid 2px;}
    /*导航*/
    .nav_bg{ width:100%; height:44px; background:#458ce3;}
    .navmenu{ width:1190px; line-height:44px; margin:0px auto; position:relative;}
    .navmenu .navr{height:44px; line-height:44px; }
    .navmenu .navr a{ font:16px/44px '微软雅黑'; color:#fff; height:44px; display:block;  float:left; min-width: 120px; text-align: center; margin: 0 21px;}
    .navmenu .navr a:hover{ background:#bd271f;}
    .navmenu .navr a:nth-child(1){ margin-left: 0; }

    /*底部开始*/
    .zfoot_bg{ background-color:#494949; width:100%; height:auto; min-width:1190px; overflow:hidden; margin:0 auto ;}
    .zsever{ width:1190px; margin:0px auto; height:97px;}
    .icon {padding:34px 0px 0px; overflow:hidden;}
    .icon li {float: left; width:300px; margin-left:85px;}
    .icon li h1{  width:39px; height:44px; line-height:44px; font-weight:normal; border-radius:50px; text-align:center; display:block; float:left;}
    .icon li span{ font:18px/44px ''; color:#9a9a9a; display:block; float:left;height:55px;  margin-left:15px; }
    .icon li.i1 {background:url(../../assets/image/i1.png) no-repeat; }
    .icon li.i2 {background:url(../../assets/image/i2.png) no-repeat;}
    .icon li.i3 {background:url(../../assets/image/i3.png) no-repeat;}
    .icon img {  float: left;  height:52px; vertical-align: top;  -webkit-transition: transform .3s;  -moz-transition: transform .3s;  -ms-transition: transform .3s;  -o-transition: transform .3s;  transition: transform .3s;}

    .zfoot{width:1190px; margin:0 auto; padding: 20px 0; font-family:"微软雅黑"; font-size:14px;  border-top:#5a5a5a solid 1px; color:#5fac47;}
    .zfoot p{ line-height:26px; text-align:center; color:#a8a8a8;}
    .zfoot ul{ padding-left:408px;}
    .zfoot ul li{ float:left;padding:0px 10px; border-right:1px #a8a8a8 solid; height:20px; line-height:20px;}
    .zfoot ul li a{ color:#a8a8a8; font-size:14px;}
    .zfoot ul li a:hover{ color:#fff; }
    .zfoot ul li:last-child{ border:none;}

    .fhtop{ position:fixed; right:240px; bottom:21%; z-index:9999;}
    @charset "utf-8";
    .main{ width:100%; margin: 0 auto;  background:#f3f3f3; overflow:hidden;}
    .main_01{ width:1190px; margin: 30px auto 0; }
    /*首页幻灯片开始*/
    .fullSlide{ width:753px;  height:277px;  position:relative;  float: left; overflow: hidden; }
    .fullSlide .bd{ margin:0 auto; position:relative; z-index:0; overflow:hidden;  }
    .fullSlide .bd ul{ width:100% !important;  }
    .fullSlide .bd li{ width:100% !important;  height:277px; overflow:hidden; text-align:center;  }
    .fullSlide .bd li a{ display:block; height:277px; }
    .fullSlide .hd{ width:753px;   z-index:1; bottom:4px;  margin:0 auto; height:30px; line-height:30px;  position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;  }
    .fullSlide .hd ul{ text-align:center;  }
    .fullSlide .hd ul li{ cursor:pointer; display:inline-block; *display:inline; zoom:1; width:11px; height:11px; border-radius:50px;  margin:4px; background:#fff; overflow:hidden; line-height:9999px;    }
    .fullSlide .hd ul .on{  width:21px; height:11px; background-color:#3772b2; border-radius:50px; }
    /*幻灯片结束*/

    /*企业简介*/
    .about{ width:412px; height:277px;  float: right; }
    .about_tit{ width:100%;   }
    .about_tit a{ color:#bd271f;font:bold 20px/40px '微软雅黑';  display: block; }
    .about_tit i{ width:76px; height:2px; background: #e2e2e2;  display: block; margin: 5px 0 0 0; }
    .about_box{ width: 100%; overflow: hidden; color:#5f5f5f;font:15px/32px '微软雅黑'; margin: 5px 0 0 0; }
    .about_box a{  color:#ff0000;font:15px/32px '微软雅黑'; }
    .about_box a:hover{  color:#569fdd;  }

    /*广告*/
    .adv1{ width:1190px; margin:30px auto 0; overflow:hidden;}
    .adv2{ width:753px; height: 80px; overflow:hidden;}
    .adv3{ width:345px; height:102px; overflow:hidden; margin: 10px 0 12px 0;}

    /*产品楼层*/
    .floor{ width:1190px; margin:30px auto 0; overflow:hidden;  background:#fff; }
    .floor_tit{height:62px;  width:1152px; border-bottom:2px solid #bd271f ; overflow: hidden; }
    .floor_tit i{ display:inline-block; float:left; font: bold 20px/50px ''; color:#ff4200; margin-left:5px;}
    .floor_tit font{display:inline-block; float:left;font-size:19px; color:#0065ab; font-weight:bold; font-family:"微软雅黑"}
    .floor_tit a{font-size:16px; color:#7e7e7e; float:right; height:42px; line-height:42px !important; padding-top:12px;  font-family:"微软雅黑";  }
    .floor_tit a:hover{  color:#ff0000;}
    .tabs{ width:1190px; overflow:hidden;}
    .menu { height:42px;line-height:42px;   padding-top:20px; float: left;margin-left: 10px; }
    .menu li a{ display:inline-block;float:left;width:auto;  font:18px/42px '微软雅黑'; color:#ff0000;cursor:pointer;text-align:center;  overflow:hidden; height:42px;  padding:0 12px;  }
    .menu .on a{ color:#fff; background:#bd271f; min-width: 92px;}
    .menu span{ display:inline-block; float:left; font-size:14px; color:#595757;}
    .lc_list{ margin:20px auto 0; height:auto; width:1152px;}
    .lc_list a{ width:207px; height:288px; display:block; float:left; margin:0 30px 26px 0;}
    .lc_list a:nth-child(1){width:209px;height:287px; margin:0 ; overflow:hidden; float:left;}
    .lc_list a:nth-child(1) img{ width:100%; height:100%;}
    .lc_list a:nth-child(2){ margin-left:25px;}
    .lc_list a:nth-child(5){ margin-right:0;}

    .lc_list img{ width:206px; height:206px;}
    .lc_list p{ display:inline-block; width:100%; height:25px;  overflow:hidden; color:#000000; font:14px/25px '微软雅黑'; margin-top:10px;}
    .lc_list p span{ color:#40b01e;}
    .lc_list i{ font:15px/25px '微软雅黑'; color:#ec2038;}
    .lc_list s{ font:13px/25px '微软雅黑'; color:#8f8f8f;}
    .lc_list span{ width:100%; font:14px/20px '微软雅黑'; color:#727272; display:block; text-align:left}
    .lc_list strong{  color:#bfbfbf; font:12px/20px '微软雅黑'; float:left; width:35%; text-align:right}
    .lc_list p:hover{color:#ff0000;}


    /*行业资讯*/
    .hyzxtit { width:1190px; height:45px; overflow:hidden; margin:30px auto 0}
    .hyzxtit h1{ font:23px/45px ''; color:#555555; height:45px; overflow:hidden; float: left;}
    .hyzxtit ul{ float: left; margin-left: 10px;}
    .hyzxtit ul li { display: block; font:16px/45px ''; height:45px; color:#666666; float: left; margin: 0 10px 0 10px;}
    .hyzxtit ul li a{ font:16px/45px ''; color:#666666; height:45px; overflow:hidden; display: block;  }
    .hyzxtit ul li a:hover{color:#ff0000;}
    .hyzxbox{ width:1190px; overflow:hidden; margin:15px auto 0;}
    .hyzxbox_l{ float: left;  }
    .hyzxbox_r{ width: 390px;  float: left; margin:0 0 0 30px}
    .hyzxbox_l_l{ width: 367px; border-right: #e5e5e5 solid 1px; margin:25px 0 25px 0;}
    /*格莱动态*/
    .gldt{ width: 100%; border-bottom: #e5e5e5 solid 1px; padding-bottom: 10px; margin-bottom: 11px;}
    .gldt li{height: 36px; background: url(../../assets/image/icon2.png) left center no-repeat; padding-left:12px;  }
    .gldt li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .gldt li:nth-child(1){background: url(../../assets/image/icon1.png) left center no-repeat; padding-left:15px;}
    .gldt li:nth-child(1) a{ font:18px/36px '微软雅黑';color:#2c2c2c;  }
    .gldt li a:hover{color:#ff0000;}
    /*行业动态*/
    .hydt{ width: 100%; margin: 0 0 10px 0;}
    .hydt li{height: 36px; background: url(../../assets/image/icon2.png) left center no-repeat; padding-left:12px;  }
    .hydt li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .hydt li:nth-child(1){background: url(../../assets/image/icon1.png) left center no-repeat; padding-left:15px;}
    .hydt li:nth-child(1) a{ font:18px/36px '微软雅黑';color:#2c2c2c;  }
    .hydt li:nth-child(2){ height: 70px; }
    .hydt li a:hover{color:#ff0000;}
    .hydt span{ color:#959595; font:13px/24px ''; margin: 0 15px 0 15px; overflow: hidden; display: block; }
    .hydt span a{ color:#ff0000; font:13px/24px '';  }
    /*行业展会*/
    .hyzh{ width: 385px; margin: 25px 0 10px 0; overflow: hidden;  border-bottom: #e5e5e5 solid 1px; padding-bottom: 16px;}
    .hyzh_t{width:345px; height: 144px; margin:0 0 0 39px;}
    .hyzh_t .img{  width:100%; height: 104px; }
    .hyzh_t .img a{ width:168px; height: 104px; overflow: hidden; }
    .hyzh_t .img a:nth-child(1){ float: left; }
    .hyzh_t .img a:nth-child(2){ float: right; }
    .hyzh_t h1{ font:18px/36px '微软雅黑';color:#2c2c2c; height: 36px; overflow: hidden; display: block; margin: 8px 0 0 0; }
    .hyzh li{height: 36px; background: url(../../assets/image/icon3.png) left center no-repeat; padding-left:20px; overflow: hidden; margin-left: 38px;}
    .hyzh li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .hyzh li a:hover{color:#ff0000;}
    .hydt li:nth-child(1) a{ font:18px/36px '微软雅黑';color:#2c2c2c;  }
    .hydt li:nth-child(2){ height: 70px; }
    /*行情速递*/
    .hq{ margin: 0 0 0 35px;  }
    .hq1{border:0;  }
    /*市场综述*/
    .sczs{ width: 100%; margin: 0 0 10px 0;}
    .sczs li{height: 36px; background: url(../../assets/image/icon2.png) left center no-repeat; padding-left:12px;  }
    .sczs li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .sczs li a:hover{color:#ff0000;}



    /*友情链接*/
    .link{ width:1190px; overflow:hidden; margin:30px auto 0;}
    .link .tit{ width:100%; height:45px;}
    .link .tit h1{ font:23px/45px ''; color:#555555; height:45px; overflow:hidden; float: left;}
    .link .tit a{ font:16px/45px ''; color:#a0a0a0; height:45px; overflow:hidden; float:right;}
    .link .tit a:hover{color:#bd271f;}
    .link .box{ width:100%; height:110px; background:#fff; margin:10px auto 30px;}
    .link .box a{ width: 126px; height:70px;  display: block;  overflow: hidden; float: left; margin: 20px 0 0 20px; }
    .link .box img{ width:100%; height:100%;transition:all 0.4s ease-in-out;
        -webkit-transition:all 0.4s ease-in-out;
        -moz-transition:all 0.4s ease-in-out;
        -o-transition:all 0.4s ease-in-out;}
    .link .box img:hover{transform:scale(1.1);
        -webkit-transform:scale(1.1);
        -moz-transform:scale(1.1);
        -o-transform:scale(1.1);
        -ms-transform:scale(1.1); }
    .top-content{
        font-size: 14px;
    }
    .ns-text-color{
        color:#e64b08 !important;
    }
    .header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #fff;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 31px;
                .change-city {
                    cursor: pointer;
                    margin-left: 5px;
                    &:hover {
                        color: $base-color;
                        border-color: $base-color;
                    }
                }
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color;
                    }
                    &.el-dropdown:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.shop-sort{
		border-top-left-radius: 20px;
	}
	.el-button--primary{
		margin-right: 4px;
		margin-top: 2.5px;;
		border-radius: 20px !important;
	}
	.header-in {
			background-color:#ffffff ;
		width: $width;
		height: 89px;
		margin: 20px auto 0;

		.logo-wrap {
			width: 240px;
			height: 68px;
			line-height: 68px;
			display: block;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.in-sousuo {
			width: 550px;
			margin-top: 10px;

			.sousuo-box {
				width: 100%;
				height: 42px;
				border: 2px solid $base-color;
				box-sizing: border-box;
				border-radius: 20px;

				// border-radius: 8px;
				.el-dropdown {
					padding: 0 10px;
					cursor: pointer;

					&::after {
						content: '';
						border-left: 1px solid #cfcfcf;
						margin-left: 5px;
					}
				}

				input {
					width: 380px;
					height: 30px;
					background: none;
					outline: none;
					border: none;
					margin: 4px;
				}

				button {
					border-radius: 0;
					float: right;
				}
			}

			.hot-search-words {
				width: 100%;
				height: 20px;
				margin-top: 5px;
				font-size: 12px;

				span {
					float: left;
				}

				ul {
					overflow: hidden;
					margin: 0;
					height: 21px;
					padding: 0;
					color: $ns-text-color-black;

					li {
						cursor: pointer;
						list-style: none;
						float: left;
						margin-right: 10px;

						&:hover {
							color: $base-color;
						}
					}
				}
			}
		}

		.cart-wrap {
			position: relative;
			float: right;

			.cart {
				margin-top: 10px;
				width: 95px;
				height: 36px;
				padding: 0 28px 0 19px;
				border: 1px solid #dfdfdf;
				color: $base-color;
				font-size: $ns-font-size-sm;
				display: block;
				position: absolute;
				right: 0;
				z-index: 101;

				span {
					cursor: pointer;
					line-height: 38px;
					margin-right: 10px;
				}
			}

			&:hover {
				.cart {
					border-bottom: 1px solid #ffffff;
				}

				.list {
					display: block;
				}
			}

			.list {
				position: absolute;
				top: 47px;
				right: 0;
				width: 340px;
				background: #fff;
				border: 1px solid #dfdfdf;
				display: none;
				z-index: 100;

				h4 {
					height: 25px;
					padding: 6px 8px;
					line-height: 25px;
				}

				.overflow-wrap {
					width: 340px;
					overflow: hidden;

					ul {
						max-height: 335px;

						&.overflow {
							overflow: auto;
							width: 354px;
						}

						li {
							background-color: #fff;
							display: block;
							font-size: 12px;
							padding: 8px 10px;
							position: relative;
							border-bottom: 1px solid #dfdfdf;
							overflow: hidden;

							.img-wrap {
								width: 50px;
								height: 50px;
								margin-right: 5px;
								overflow: hidden;
								float: left;
								text-align: center;
								line-height: 50px;
							}

							.goods-name {
								float: left;
								width: 140px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
							}

							.operation {
								float: right;
								text-align: right;
								width: 90px;

								p {
									color: $base-color;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								span {
									cursor: pointer;

									&:hover {
										color: $base-color;
									}
								}
							}

							&:last-child {
								border-bottom: 0;
							}
						}
					}
				}

				.total {
					background-color: #fff;
					display: block;
					font-size: 12px;
					padding: 8px 10px;
					position: relative;
					border-bottom: 1px solid #dfdfdf;
					overflow: hidden;
					background-color: #f0f0f0;
					border-bottom: 0;
					left: 0;

					span {
						margin-top: 5px;
						display: inline-block;
						width: 70%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						strong {
							margin: 0 2px;
							color: $base-color;
							font-size: $ns-font-size-base;
						}
					}

					button {
						float: right;
					}
				}

				.empty {
					width: auto;
					height: 70px;
					line-height: 70px;
					text-align: center;
					color: #999;

					i {
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-size: 12px;
						padding-right: 20px;
						margin-left: 10px;
					}
				}
			}
		}
	}
</style>