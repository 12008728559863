<template>

    <div class="footer">
        <!-------友情链接--------->
        <div class="link">
            <div class="tit">
                <h1>友情链接</h1><router-link to="/member/footprint">立即申请加入></router-link>
            </div>
            <div class="box">
                <a v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title" @click="linkUrl(link_item.link_url, link_item.is_blank)" href="javascript:;"><img :src="$img(link_item.link_pic)"></a>

            </div>
        </div>







        <!--主体结束-->
        <div class="clear"></div>

        <!--底部开始-->
        <div class="zfoot_bg">
            <div class="zsever">
                <ul class="icon clearfix">
                    <li class="i1"><a href=""><h1></h1><span><b>品质</b>  ·   用心经营  服务第一</span></a></li>
                    <li class="i2"><a href=""><h1></h1><span><b>低价</b>  ·   普惠实价  帮您省钱</span></a></li>
                    <li class="i3"><a href=""><h1></h1><span><b>速达</b>  ·   专业服务  按需定制</span></a></li>
                </ul>
            </div>

            <div class="clear"></div>

            <div class="zfoot">
                <ul>
                    <li v-for="(nav_item, nav_index) in navList"
                        :key="nav_index" @click="navUrl(nav_item.url, nav_item.is_blank)"><a>{{ nav_item.nav_title }}</a></li>

                </ul>
                <div class="clear"></div>
                <p><copy-right /></p>
            </div>
        </div>
<!--        <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0">-->
<!--            <el-tab-pane label="友情链接" name="first">-->
<!--                <div>-->
<!--                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">-->
<!--                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)"/></span>-->
<!--                    </div>-->

<!--                    <div class="clear"></div>-->
<!--                </div>-->
<!--            </el-tab-pane>-->
<!--        </el-tabs>-->
        <!-- <div class="friendly-link" v-if="linkList.length > 0">
			<div class="link-title">友情链接</div>
			<div>
				<div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
					<span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)" /></span>
				</div>

				<div class="clear"></div>
			</div>
		</div> -->
<!--        <div class="footer-top" v-if="shopServiceList.length > 0">-->
<!--            <ul class="service">-->
<!--               &lt;!&ndash; <li v-for="(item, index) in shopServiceList" :key="index">-->
<!--                    <div><i class="iconfont" :class="item.pc_icon"></i></div>-->
<!--                    <p>{{ item.name }}</p>-->
<!--                </li> &ndash;&gt;-->
<!--				<li >-->
<!--				    <div style="border: #e53134 solid;border-radius: 100%;font-size: 28px;font-weight: bold;color:#e53134;">多</div>-->
<!--				    <p>品类齐全<span style="font-size:24px;">·</span>轻松购物</p>-->
<!--				</li>-->
<!--				<li >-->
<!--				    <div style="border: 3px #e53134 solid;border-radius: 100%;font-size: 28px;font-weight: bold;color:#e53134;">品</div>-->
<!--				    <p>品质保证<span style="font-size:24px;">·</span>服务周到</p>-->
<!--				</li>-->
<!--				<li >-->
<!--				    <div style="border: 3px #e53134 solid;border-radius: 100%;font-size: 28px;font-weight: bold;color:#e53134;">售</div>-->
<!--				    <p>贴心售后<span style="font-size:24px;">·</span>专业解答</p>-->
<!--				</li>-->
<!--				<li >-->
<!--				    <div style="border: 3px #e53134 solid;border-radius: 100%;font-size: 28px;font-weight: bold;color:#e53134;">省</div>-->
<!--				    <p>天天低价<span style="font-size:24px;">·</span>畅享无忧</p>-->
<!--				</li>-->
<!--            </ul>-->
<!--        </div>-->

        <!-- <div class="footer-center">
            
        </div> -->

<!--        <div class="footer-bot" >-->
<!--				<div class="left" v-if="siteInfo.web_phone || siteInfo.web_email">-->
<!--				    <div>-->
<!--				        <p class="left-phone" v-if="siteInfo.web_phone">{{ siteInfo.web_phone }}</p>-->
<!--				        <p class="left-email">{{ siteInfo.web_email }}</p>-->
<!--				    </div>-->
<!--				</div>-->
<!--				<div class="center" v-if="helpList.length">-->
<!--				    <div class="help" v-for="(item, index) in helpList" :key="index">-->
<!--				        <p @click="clickToHelp(item.class_id)">{{ item.class_name }}</p>-->
<!--				        <ul class="help-ul">-->
<!--				            <li class="help-li" v-for="(helpItem, helpIndex) in item.child_list" :key="helpIndex" @click="clickToHelpDetail(helpItem.id)">{{ helpItem.title }}</li>-->
<!--				        </ul>-->
<!--				    </div>-->
<!--				</div>-->
<!--				<div class="right">-->
<!--				    <el-image  v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)" @error="imageError" fit="contain">-->
<!--						&lt;!&ndash; <div slot="error" class="image-slot">-->
<!--							<img :src="require('@/assets/images/wxewm.png')" />-->
<!--						</div> &ndash;&gt;-->
<!--					</el-image>-->
<!--				    <el-image v-else :src="require('@/assets/images/wxewm.png')"></el-image>-->
<!--				    <p>扫一扫</p>-->
<!--				</div>-->
<!--				-->
<!--		</div>-->
<!--		<div style="-->
<!--    margin: 0;-->
<!--    padding: 0;-->
<!--   -->
<!--    margin: 0 auto;-->
<!--    padding: 1px 0;-->
<!--    box-sizing: border-box;-->
<!--    border-bottom: 1px solid #fff;-->
<!--    display: flex;-->
<!--    justify-content: space-around;-->
<!--    align-items: center;">-->
<!--	</div>-->
<!--		<div class="footer-fot">-->
<!--			<copy-right />-->
<!--		</div>-->
    </div>
</template>

<script>
    import { copyRight, shopServiceLists, friendlyLink } from "@/api/website"
    import CopyRight from "./CopyRight"
    import { mapGetters } from "vuex"
    import { helpList } from "@/api/cms/help"
    import { navList } from "@/api/website"

    export default {
        props: {},
        data() {
            return {
                shopServiceList: [],
                linkList: [],
                helpList: [],
                ishide: false,
                navList: [],
                activeName: "first",
            }
        },
        computed: {
            ...mapGetters(["siteInfo"])
        },
        created() {
            this.getShopServiceLists()
            this.link()
            this.getHelpList()
            this.nav();
        },
        mounted() {},
        watch: {},
        methods: {
            nav() {
                navList({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.navList = res.data.list;
                            for (let i in this.navList) {
                                this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
                            }
                            this.initNav(this.$route.path);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },
            navUrl(url, target) {
                if (!url) return;
                if (url.indexOf('http') == -1) {
                    if (target) this.$router.pushToTab({ path: url });
                    else this.$router.push({ path: url });
                } else {
                    if (target) window.open(url);
                    else window.location.href = url;
                }
            },
            initNav(path) {
                for (let i in this.navList) {
                    if (this.navList[i]['url'] == path) {
                        this.navSelect = path;
                        continue;
                    }
                }
            },
            getShopServiceLists() {
                shopServiceLists({}).then(res => {
                    if (res.code == 0 && res.data) {
                        this.shopServiceList = res.data
                    }
                })
            },
            link() {
                friendlyLink({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.linkList = res.data.list
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            linkUrl(url, target) {
                if (!url) return
                if (url.indexOf("http") == -1) {
                    if (target) this.$router.pushToTab({ path: url })
                    else this.$router.push({ path: url })
                } else {
                    if (target) window.open(url)
                    else window.location.href = url
                }
            },
            /**
             * 获取帮助列表
             */
            getHelpList() {
                helpList()
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            var arr = [];
                            arr = res.data.slice(0, 4)

                            for (let i=0; i<arr.length; i++) {
                                arr[i].child_list = arr[i].child_list.slice(0, 4);
                            }

                            this.helpList = arr
                        }
                    })
                    .catch(err => {})
            },
            /**
             * 图片加载失败
             */
            imageError() {
                this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
            },
            /**
             * 跳转到帮助列表
             */
            clickToHelp(id) {
                this.$router.push("/cms/help/listother-" + id)
            },
            /**
             * 跳转到帮助详情
             */
            clickToHelpDetail(id) {
                this.$router.push("/cms/help-" + id)
            }
        },
        components: { CopyRight }
    }
</script>

<style scoped>
    @charset "utf-8";
    /* CSS Document */
    body {font-family: "宋体",Helvetica,Arial,sans-serif;font-size:12px; margin:0;padding:0;color:#333; background-color:#fff;}
    div { float:none; margin:0px auto; padding:0;}
    body>div{margin:0 auto}
    form,img,ul,ol,li,dl,dt,dd,input,p,br,button{margin: 0; padding: 0; border: 0;}
    ul,li,ol {list-style: none;}
    h1,h2,h3,h4,h5,h6 { margin:0; padding:0;}
    a { text-decoration: none; color: #333 }
    a:hover { text-decoration: none;}
    i,em { font-style:normal;}
    img { border:0;}
    .clear { clear:both; line-height:0; height:0; display:block;}
    .fr { float: right; }
    .fl { float: left; }
    a:focus { outline:none;}
    a:hover{ -webkit-transition:all 0.5s;-webkit-transiton-delay:0.6s;}
    .trans{transition: all .3s ease;-webkit-transition: all .3s ease;-moz-transition: all .3s ease;-ms-transition: all .3s ease;-o-transition: all .3s ease;-webkit-backface-visibility: hidden;}
    .trans:hover{transform:scale(1.04,1.04);-ms-transform:scale(1.04,1.04); -webkit-transform:scale(1.04,1.04);}
    /**头部样式**/

    .ztoolbar_bg { width: 100%; height:40px; line-height:40px;min-width:1190px; background:#f0f0f0;  overflow:hidden; }
    .ztoolbar{ width: 1190px; height:auto; color:#494949;font:14px/40px '宋体';}
    .ztoolbar .fl:nth-child(1) a{ color:#ff7200;}
    .ztoolbar .fl:nth-child(1) a:hover{ color:#666666}
    .ztoolbar .zright{color:#494949; font-family:"宋体"}
    .ztoolbar .zright a { display:inline-block; margin:0 5px;color:#525252;font:12px/40px '宋体';}
    .ztoolbar .zright a:hover{ color:#f98e0a;}
    .topbj{ background:#fff;}
    .ztop { height:147px;width: 1190px;}
    .ztop .zlogo {margin-top:29px; overflow:hidden;}
    /*搜索开始*/
    .zsearch{ float:left; height:85px;width:527px; margin:40px 0px 0px 90px;}
    .zsearch form input[type="text"]{ width:430px; height:44px; line-height:33px; border:2px #bd271f solid; background-color:#fff; color:#c8c8c8; text-indent:1em; outline:none; float:left; font:14px /44px '宋体'; color:#CCC;}
    .zsearch form input[type="submit"]{ width:90px; height:48px; border:none; float:left;outline:none; font-size:14px;letter-spacing:4px; color:#ffffff; background:url(../../assets/image/search_btn.png) no-repeat center #bd271f;  cursor:pointer;}
    .zsearch_key { margin-top:58px; margin-right:50px; color: #b2b2b2; font-size:14px;margin-left: 20px;font-family:"微软雅黑" }
    .zsearch_key a { color: #b2b2b2; text-decoration: none; padding-right:12px; }
    .zsearch_key a:nth-child(2){ color:#bd271f}
    .zsearch_key a:hover { color:#ff7e26;}
    /*二维码*/
    .ewm { width:180px; margin-top:35px; }
    .ewm h1{ float: left; margin: 22px 0 0 0; }
    .ewm .img{ width: 78px; height: 78px; float: right; border: #f3f3f3 solid 2px;}
    /*导航*/
    .nav_bg{ width:100%; height:44px; background:#458ce3;}
    .navmenu{ width:1190px; line-height:44px; margin:0px auto; position:relative;}
    .navmenu .navr{height:44px; line-height:44px; }
    .navmenu .navr a{ font:16px/44px '微软雅黑'; color:#fff; height:44px; display:block;  float:left; min-width: 120px; text-align: center; margin: 0 21px;}
    .navmenu .navr a:hover{ background:#bd271f;}
    .navmenu .navr a:nth-child(1){ margin-left: 0; }

    /*底部开始*/
    .zfoot_bg{ background-color:#494949; width:100%; height:auto; min-width:1190px; overflow:hidden; margin:0 auto ;}
    .zsever{ width:1190px; margin:0px auto; height:97px;}
    .icon {padding:34px 0px 0px; overflow:hidden;}
    .icon li {float: left; width:300px; margin-left:85px;}
    .icon li h1{  width:39px; height:44px; line-height:44px; font-weight:normal; border-radius:50px; text-align:center; display:block; float:left;}
    .icon li span{ font:18px/44px ''; color:#9a9a9a; display:block; float:left;height:55px;  margin-left:15px; }
    .icon li.i1 {background:url(../../assets/image/i1.png) no-repeat; }
    .icon li.i2 {background:url(../../assets/image/i2.png) no-repeat;}
    .icon li.i3 {background:url(../../assets/image/i3.png) no-repeat;}
    .icon img {  float: left;  height:52px; vertical-align: top;  -webkit-transition: transform .3s;  -moz-transition: transform .3s;  -ms-transition: transform .3s;  -o-transition: transform .3s;  transition: transform .3s;}

    .zfoot{width:1190px; margin:0 auto; padding: 20px 0; font-family:"微软雅黑"; font-size:14px;  border-top:#5a5a5a solid 1px; color:#5fac47;}
    .zfoot p{ line-height:26px; text-align:center; color:#a8a8a8;}
    .zfoot ul{ padding-left:408px;}
    .zfoot ul li{ float:left;padding:0px 10px; border-right:1px #a8a8a8 solid; height:20px; line-height:20px;}
    .zfoot ul li a{ color:#a8a8a8; font-size:14px;}
    .zfoot ul li a:hover{ color:#fff; }
    .zfoot ul li:last-child{ border:none;}

    .fhtop{ position:fixed; right:240px; bottom:21%; z-index:9999;}
    @charset "utf-8";
    .main{ width:100%; margin: 0 auto;  background:#f3f3f3; overflow:hidden;}
    .main_01{ width:1190px; margin: 30px auto 0; }
    /*首页幻灯片开始*/
    .fullSlide{ width:753px;  height:277px;  position:relative;  float: left; overflow: hidden; }
    .fullSlide .bd{ margin:0 auto; position:relative; z-index:0; overflow:hidden;  }
    .fullSlide .bd ul{ width:100% !important;  }
    .fullSlide .bd li{ width:100% !important;  height:277px; overflow:hidden; text-align:center;  }
    .fullSlide .bd li a{ display:block; height:277px; }
    .fullSlide .hd{ width:753px;   z-index:1; bottom:4px;  margin:0 auto; height:30px; line-height:30px;  position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;  }
    .fullSlide .hd ul{ text-align:center;  }
    .fullSlide .hd ul li{ cursor:pointer; display:inline-block; *display:inline; zoom:1; width:11px; height:11px; border-radius:50px;  margin:4px; background:#fff; overflow:hidden; line-height:9999px;    }
    .fullSlide .hd ul .on{  width:21px; height:11px; background-color:#3772b2; border-radius:50px; }
    /*幻灯片结束*/

    /*企业简介*/
    .about{ width:412px; height:277px;  float: right; }
    .about_tit{ width:100%;   }
    .about_tit a{ color:#bd271f;font:bold 20px/40px '微软雅黑';  display: block; }
    .about_tit i{ width:76px; height:2px; background: #e2e2e2;  display: block; margin: 5px 0 0 0; }
    .about_box{ width: 100%; overflow: hidden; color:#5f5f5f;font:15px/32px '微软雅黑'; margin: 5px 0 0 0; }
    .about_box a{  color:#ff0000;font:15px/32px '微软雅黑'; }
    .about_box a:hover{  color:#569fdd;  }

    /*广告*/
    .adv1{ width:1190px; margin:30px auto 0; overflow:hidden;}
    .adv2{ width:753px; height: 80px; overflow:hidden;}
    .adv3{ width:345px; height:102px; overflow:hidden; margin: 10px 0 12px 0;}

    /*产品楼层*/
    .floor{ width:1190px; margin:30px auto 0; overflow:hidden;  background:#fff; }
    .floor_tit{height:62px;  width:1152px; border-bottom:2px solid #bd271f ; overflow: hidden; }
    .floor_tit i{ display:inline-block; float:left; font: bold 20px/50px ''; color:#ff4200; margin-left:5px;}
    .floor_tit font{display:inline-block; float:left;font-size:19px; color:#0065ab; font-weight:bold; font-family:"微软雅黑"}
    .floor_tit a{font-size:16px; color:#7e7e7e; float:right; height:42px; line-height:42px !important; padding-top:12px;  font-family:"微软雅黑";  }
    .floor_tit a:hover{  color:#ff0000;}
    .tabs{ width:1190px; overflow:hidden;}
    .menu { height:42px;line-height:42px;   padding-top:20px; float: left;margin-left: 10px; }
    .menu li a{ display:inline-block;float:left;width:auto;  font:18px/42px '微软雅黑'; color:#ff0000;cursor:pointer;text-align:center;  overflow:hidden; height:42px;  padding:0 12px;  }
    .menu .on a{ color:#fff; background:#bd271f; min-width: 92px;}
    .menu span{ display:inline-block; float:left; font-size:14px; color:#595757;}
    .lc_list{ margin:20px auto 0; height:auto; width:1152px;}
    .lc_list a{ width:207px; height:288px; display:block; float:left; margin:0 30px 26px 0;}
    .lc_list a:nth-child(1){width:209px;height:287px; margin:0 ; overflow:hidden; float:left;}
    .lc_list a:nth-child(1) img{ width:100%; height:100%;}
    .lc_list a:nth-child(2){ margin-left:25px;}
    .lc_list a:nth-child(5){ margin-right:0;}

    .lc_list img{ width:206px; height:206px;}
    .lc_list p{ display:inline-block; width:100%; height:25px;  overflow:hidden; color:#000000; font:14px/25px '微软雅黑'; margin-top:10px;}
    .lc_list p span{ color:#40b01e;}
    .lc_list i{ font:15px/25px '微软雅黑'; color:#ec2038;}
    .lc_list s{ font:13px/25px '微软雅黑'; color:#8f8f8f;}
    .lc_list span{ width:100%; font:14px/20px '微软雅黑'; color:#727272; display:block; text-align:left}
    .lc_list strong{  color:#bfbfbf; font:12px/20px '微软雅黑'; float:left; width:35%; text-align:right}
    .lc_list p:hover{color:#ff0000;}


    /*行业资讯*/
    .hyzxtit { width:1190px; height:45px; overflow:hidden; margin:30px auto 0}
    .hyzxtit h1{ font:23px/45px ''; color:#555555; height:45px; overflow:hidden; float: left;}
    .hyzxtit ul{ float: left; margin-left: 10px;}
    .hyzxtit ul li { display: block; font:16px/45px ''; height:45px; color:#666666; float: left; margin: 0 10px 0 10px;}
    .hyzxtit ul li a{ font:16px/45px ''; color:#666666; height:45px; overflow:hidden; display: block;  }
    .hyzxtit ul li a:hover{color:#ff0000;}
    .hyzxbox{ width:1190px; overflow:hidden; margin:15px auto 0;}
    .hyzxbox_l{ float: left;  }
    .hyzxbox_r{ width: 390px;  float: left; margin:0 0 0 30px}
    .hyzxbox_l_l{ width: 367px; border-right: #e5e5e5 solid 1px; margin:25px 0 25px 0;}
    /*格莱动态*/
    .gldt{ width: 100%; border-bottom: #e5e5e5 solid 1px; padding-bottom: 10px; margin-bottom: 11px;}
    .gldt li{height: 36px; background: url(../../assets/image/icon2.png) left center no-repeat; padding-left:12px;  }
    .gldt li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .gldt li:nth-child(1){background: url(../../assets/image/icon1.png) left center no-repeat; padding-left:15px;}
    .gldt li:nth-child(1) a{ font:18px/36px '微软雅黑';color:#2c2c2c;  }
    .gldt li a:hover{color:#ff0000;}
    /*行业动态*/
    .hydt{ width: 100%; margin: 0 0 10px 0;}
    .hydt li{height: 36px; background: url(../../assets/image/icon2.png) left center no-repeat; padding-left:12px;  }
    .hydt li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .hydt li:nth-child(1){background: url(../../assets/image/icon1.png) left center no-repeat; padding-left:15px;}
    .hydt li:nth-child(1) a{ font:18px/36px '微软雅黑';color:#2c2c2c;  }
    .hydt li:nth-child(2){ height: 70px; }
    .hydt li a:hover{color:#ff0000;}
    .hydt span{ color:#959595; font:13px/24px ''; margin: 0 15px 0 15px; overflow: hidden; display: block; }
    .hydt span a{ color:#ff0000; font:13px/24px '';  }
    /*行业展会*/
    .hyzh{ width: 385px; margin: 25px 0 10px 0; overflow: hidden;  border-bottom: #e5e5e5 solid 1px; padding-bottom: 16px;}
    .hyzh_t{width:345px; height: 144px; margin:0 0 0 39px;}
    .hyzh_t .img{  width:100%; height: 104px; }
    .hyzh_t .img a{ width:168px; height: 104px; overflow: hidden; }
    .hyzh_t .img a:nth-child(1){ float: left; }
    .hyzh_t .img a:nth-child(2){ float: right; }
    .hyzh_t h1{ font:18px/36px '微软雅黑';color:#2c2c2c; height: 36px; overflow: hidden; display: block; margin: 8px 0 0 0; }
    .hyzh li{height: 36px; background: url(../../assets/image/icon3.png) left center no-repeat; padding-left:20px; overflow: hidden; margin-left: 38px;}
    .hyzh li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .hyzh li a:hover{color:#ff0000;}
    .hydt li:nth-child(1) a{ font:18px/36px '微软雅黑';color:#2c2c2c;  }
    .hydt li:nth-child(2){ height: 70px; }
    /*行情速递*/
    .hq{ margin: 0 0 0 35px;  }
    .hq1{border:0;  }
    /*市场综述*/
    .sczs{ width: 100%; margin: 0 0 10px 0;}
    .sczs li{height: 36px; background: url(../../assets/image/icon2.png) left center no-repeat; padding-left:12px;  }
    .sczs li a{  color:#5f5f5f; font:15px/36px '微软雅黑'; }
    .sczs li a:hover{color:#ff0000;}



    /*友情链接*/
    .link{ width:1190px; overflow:hidden; margin:30px auto 0;}
    .link .tit{ width:100%; height:45px;}
    .link .tit h1{ font:23px/45px ''; color:#555555; height:45px; overflow:hidden; float: left;}
    .link .tit a{ font:16px/45px ''; color:#a0a0a0; height:45px; overflow:hidden; float:right;}
    .link .tit a:hover{color:#bd271f;}
    .link .box{ width:100%; height:110px; background:#fff; margin:10px auto 30px;}
    .link .box a{ width: 126px; height:70px;  display: block;  overflow: hidden; float: left; margin: 20px 0 0 20px; }
    .link .box img{ width:100%; height:100%;transition:all 0.4s ease-in-out;
        -webkit-transition:all 0.4s ease-in-out;
        -moz-transition:all 0.4s ease-in-out;
        -o-transition:all 0.4s ease-in-out;}
    .link .box img:hover{transform:scale(1.1);
        -webkit-transform:scale(1.1);
        -moz-transform:scale(1.1);
        -o-transform:scale(1.1);
        -ms-transform:scale(1.1); }
    .top-content{
        font-size: 14px;
    }
    .ns-text-color{
        color:#e64b08 !important;
    }
    .header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #fff;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 31px;
                .change-city {
                    cursor: pointer;
                    margin-left: 5px;
                    &:hover {
                        color: $base-color;
                        border-color: $base-color;
                    }
                }
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color;
                    }
                    &.el-dropdown:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.right /deep/ .el-image__inner {
		vertical-align: baseline;
		max-width: 100%;
		max-height: 100%;
	 }
</style>
<style scoped lang="scss">
    .footer {
		background: #fff;
        .footer-top {
            background-color: #fff;
            .service {
				color:#555555;
                margin: 0;
                padding: 0;
                width: $width;
                margin: 0 auto;
                padding: 50px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #adadad;
                display: flex;
                justify-content: space-around;
                align-items: center;
                li {
					display:flex;
					color: #555555;
                    list-style: none;
                    line-height: 50px;
                    text-align: center;
                    flex: 1;
                    
                    div {
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        i { 						
							color: #fff;							
                            font-size: 48px;
                            // color: $base-color;
                        }
                    }
                    p {    
						font-weight: bold;
						margin-left:8px;
						flex-direction: row;
							justify-content: center;
							align-items: center;
							display: flex;             
						font-size: 16px;
                        line-height: 20px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .footer-center {
            width: $width;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            .left {
                // width: 300px;

                .left-phone {
                    font-size: 26px;
                    font-weight: 600;
                    color: $base-color;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $base-color;
                    margin-bottom: 12px;
                }
                .left-email {
                    font-size: 18px;
                    color: #fff;
                }
            }
            .center {
                width: calc(100% - 550px);
                padding: 0 68px;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: #555;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .help-li {
                    font-size: 14px;
                    color: #fff;
                    line-height: 30px;
                    cursor: pointer;
                }

                p:hover, .help-li:hover {
                    color:#fff;
                }
            }
            .right {
                width: 250px;
                text-align: center;

                .el-image {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                p {
                    font-size: 18px;
                    color: #fff;
                }
            }

            .qrcode-hide {
                display: none;
            }
        }

        .footer-bot {
            background: #fff;
            color: #9D9D9D;
			width: $width;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 15px 0px;
			.left {
			    // width: 300px;
			
			    .left-phone {
			        font-size: 26px;
			        font-weight: 600;
			        // color: $base-color;
			        padding-bottom: 10px;
			        // border-bottom: 1px solid $base-color;
					border-bottom: 1px solid #FFFFFF;
			        margin-bottom: 12px;
			    }
			    .left-email {
			        font-size: 18px;
			        color: #fff;
			    }
			}
			.center {
			    width: calc(100% - 550px);
			    padding: 0 68px;
			    display: flex;
			    justify-content: space-between;
			
			    p {
			        font-size: 18px;
			        font-weight: 600;
			        color: #555;
			        margin-bottom: 10px;
			        cursor: pointer;
			    }
			
			    .help-li {
			        font-size: 14px;
			        color: #555;
			        line-height: 30px;
			        cursor: pointer;
			    }
			
			    p:hover, .help-li:hover {
			        color: #f0f0f0;
			    }
			}
			.right {
			    width: 250px;
			    text-align: center;
			
			    .el-image {
			        width: 120px;
			        height: 120px;
			        line-height: 120px;
			        text-align: center;
					background-color: #FFFFFF;
			    }
			
			    p {
			        font-size: 18px;
			        color: #fff;
			    }
			}
			
			.qrcode-hide {
			    display: none;
			}
        }
		
		.footer-fot {
			background: #fff;
			color: #9D9D9D;
			// width: $width;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 0px 350px;
		}
		
        .friendly-link {
            width: $width;
            margin: 0 auto;
            border: 1px solid #e9e9e9;

            .link-title {
                line-height: 30px;
                padding: 10px 0 5px;
                margin: 0px 0 15px;
                border-bottom: 1px solid #e8e8e8;
            }
            .link-item {
                width: 10%;
                height: 50px;
                line-height: 47px;
                float: left;
                text-align: center;
                overflow: hidden;
                margin: 0 6px 10px 6px;
                -webkit-transition: opacity 0.3s, box-shadow 0.3s;
                transition: opacity 0.3s, box-shadow 0.3s;
                border: 1px solid #fff;
                border-left: none;
                cursor: pointer;
            }
            .link-item:hover {
                width: -webkit-calc(10% + 1px);
                width: calc(10% + 1px);
                margin-left: 4px;
                position: relative;
                opacity: 1;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border: 1px solid #dddddd;
            }
            .clear {
                clear: both;
            }
        }
    }
</style>
<style lang="scss">
    .friendly-link {
        .el-tabs__nav-scroll {
            padding-left: 30px;
            height: 50px;
            line-height: 50px;
        }
        .el-tabs__content {
            padding: 0 20px;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
        }
        .el-tabs__nav {
            padding: 0 20px;
        }
        .el-tabs__active-bar {
            padding: 0 20px;
        }
    }
</style>
